@import '../../../../../assets/styles/mixins.scss';

.new-risk-assessment-task {
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .risk-assessment-multiselect {
        display: flex;
        >div:first-child{
            flex: 1;
        }
        .task-access {
            margin-bottom: 1.5rem;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--font-gray3);
            padding: 7px;
            border-radius: 5px;
            text-transform: capitalize;
            cursor: pointer;

            img {
                scale: 0.7;
            }
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .more-task-details {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;

        >div {
            flex: 1;
        }

        >div:not(:first-child) {
            margin-left: 10px;
        }
    }

    .action-row {
        display: flex;
        justify-content: space-between;

        .live-work {
            justify-content: start;
            align-items: center;
        }
        .info-events{
            flex: 1 1 0%;
            display: flex;
            margin-top: 5px;
            margin-left: 20px;
        }

        .action-buttons {
            display: flex;
            width: 50%;
            margin: auto;

            button {
                margin: 0 10px;
                border-radius: 5px;
            }
        }
    }
}