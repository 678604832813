@import '../../../assets/styles/mixins.scss';

.user-list-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 15px 25px;

    .header {
        display: flex;
        justify-content: space-between;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 32px;
            color: var(--font-black);

        }
        .search-filter {
            display: flex;
            justify-content: flex-end;
            // margin-top: 0.7rem;
            margin-bottom: 0.7rem;
            padding: 0 15px;
        }
    }

    .view-list-text {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
    }

    .user-actions {
        img {
            margin: 0 15px;
            cursor: pointer;
        }
    }
    .pagination {
        display: flex;
        justify-content: end;
    }
}

.edit-user{
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }
    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .user-multiselect {
        margin-bottom: 1.5rem;
    }

    .action-buttons {
        display: flex;
        width: 50%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
}