@import '../../assets/styles/mixins.scss';

.main-wrapper {
    // display: flex;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .token-expiry {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-40%, 50%);
        padding: 10px;
        background-color: var(--primary-blue-color);
        color: white;
        font-size: 1rem;
        width: 40%;
        text-align: center;
    }

    .layout-header-wrapper {
        box-shadow: 0px 8px 16px -7px rgba(3, 29, 53, 0.08);
    }

    .content {
        display: flex;
        height: 100%;
        display: flex;
        height: calc(100% - 80px);

        // flex-direction: column;
        .sidebar-menu {
            // border: 1px solid;
            min-height: 100%;
            overflow-y: auto;
            width: 17%
        }

        .dashboard-components-holder {
            min-height: 100%;
            overflow-y: auto;
            flex-grow: 1;
            width: 83%;
            background-color: var(--light-gray-background);
        }
    }
}