@import '../../assets//styles/mixins.scss';

.onboarding-container {
    width: 100%;
    top: 0;
    position: absolute;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.myCarousal {
    margin-top: 40px;

    .slide {
        .carousel-inner {
            width: 80%;
            margin: auto;

            .carousel-item {
                justify-content: center;
                display: flex;

                .carousel-content {
                    display: flex;
                    width: 90%;
                    justify-content: center;
                    margin: auto;
                }
            }
        }

        .carousel-indicators {
            position: absolute;
            bottom: -70px;
        }
    }
}

.carousal-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    min-height: 65vh;

    .left-content {
        width: 35%;
        display: flex;
        justify-content: start;
        padding: 10px;

        img {
            width: auto;
            height: auto;
            min-width: 300px;
            min-height: 300px;
            margin: auto;
        }
    }

    .right-content {
        width: 50%;
        justify-content: end;
        padding-bottom: 10px;

        .heading {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 64px;
            line-height: 72px;
            letter-spacing: -0.15px;
            color: var(--primary-white-color);
        }

        .description {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.15px;
            color: var(--primary-white-color);
            margin-top: 15px;
        }

        .input-fields {
            display: flex;
            justify-content: space-between;
            margin: 35px 0;

            .input {
                flex-grow: 1;
                margin-right: 15px;

                input {
                    width: 100%;
                    height: 40px;
                    padding: 6px;
                    border-radius: 3px;
                    outline: none;
                    border: none;
                }
            }

            .button {
                button {
                    height: 40px;
                    border-radius: 3px;
                    border: none;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.15px;
                    color: var(--primary-blue-color);
                    padding: 6px;
                }
            }
        }

        .existing-user-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.15px;
            color: var(--primary-white-color);
            position: absolute;

            .second {
                @include link();
                color: var(--primary-white-color);
            }
        }
    }
}