@import '../../assets/styles/mixins.scss';

.policy-container {
    display: flex;
    flex-flow: column;
    height: 100%;

    .page-header {
        padding: 10px 65px;
        color: var(--primary-white-color);
    }

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 46px;
            line-height: 54px;
            margin-left: 15px;
        }
    }

    .page-content {
        display: flex;
        flex-flow: column;
        height: 100%;
        background-color: var(--primary-white-color);

        .page-body{
            margin: 3%;
        }
    }
}