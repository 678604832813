@import '../../../../../assets/styles/mixins.scss';

.limits-of-machine-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;
    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
    }

    .nav-tabs {
        li {
            margin-right: 3px;

            button {
                background-color: var(--surface-grey-color);
                color: var(--font-black);
            }

            .active {
                background-color: var(--primary-blue-color);
                color: var(--primary-white-color);
            }
        }
    }

    .limits-table, .use-personel-table {
        thead th {
            background-color: black;
            color: white;
        }

        th:last-child {
            text-align: center;
        }

        tbody {
            td:last-child {
                height: 50px;
                text-align: center;
            }

        }
        td:first-child {
            overflow: unset;
            text-overflow: unset;
            white-space: unset;
            
        }
    }

    // view edit popup
}

.view-edit-machine-limits {

    .modal-content {
        padding: 16px !important;
    }

    .header {
        .label {
            flex: 1;
            text-align: center;
        }
    }

    .action-btns {
        display: flex;
        justify-content: center;

        button {
            width: 30%;
            margin:0 10px;
        }
    }
}