@import '../../assets//styles//mixins.scss';

.splash-screen {
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layer {
    background-color: var(--primary-blue-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
    z-index: -1;
}
.gradient100 {
    width: 100%;
}