@import '../../../../../assets/styles/mixins.scss';

.filter{
    .header {
        img {
            scale: 1.2;
            cursor: pointer;
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .user-multiselect {
        >div {
            flex: 1;
        }
        margin-bottom: 1.5rem;
    }

    fieldset {
        width: 100%;
        margin-bottom: 10px;

        legend {
            color: #1E1E1E;
            text-align: left;
            margin: 0;
        }

        div[role="radiogroup"] {
            display: flex;
            flex-direction: row;
        }
    }

    .action-buttons {
        display: flex;
        width: 70%;
        margin: auto;
        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
}