@import '../../../../../assets/styles/mixins.scss';

.summary-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;

    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
        margin-bottom: 24px;
    }

    .event-risk {
        display: flex;
        width: 105px;
        min-width: 105px;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 13px;
        background: #EC043C;
        color: white;

    }

    .view-notes-text {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
    }



    .summary-table {

        thead th {
            background-color: #565656;
            color: white;
        }

        th:not(:second-child) {
            text-align: center;
        }

        tbody {
            td:not(:second-child) {
                height: 50px;
                text-align: center;
            }

        }

    }

    .pagination {
        display: flex;
        justify-content: end;
    }

    .action-require {
        // flex: 1 1 0%;
        justify-content: start;
        display: flex;
        align-items: center;
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 0px 0px 1px rgba(104, 113, 130, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
        height: 100%;
    }

    .task-search-wrapper {
        position: relative;
        height: 3rem;
        input {
            height: inherit;
            width: inherit;
            padding-left: 2rem;
            border: 1px solid var(--font-gray1) !important;
            border-radius: 5px;
           
        }
        .search-img {
            position: absolute;
            left: 0.5rem;
            top: 0.9rem;
            height: 20px;
        } 
    }

}