@import '../../../assets/styles/mixins.scss';

.field-container {
    position: relative;
    height: 48px;
    width: 100%;

    label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: var(--primary-white-color);
        padding: 0 4px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--font-gray);
    }

    input {
        height: inherit;
        width: inherit;
    }

    .verificationCode {
        letter-spacing: 15px;
    }
}

.error {
    position: absolute;
    color: red;
    font-size: 0.6rem;
}

.suffix-icon {
    position: absolute;
    right: 15px;
    top: 15px;
}
