@import "./variables.scss";

// Media query mixin
@mixin for-size($size) {
    @if $size ==phone-only {
        @media (max-width: 599px) {
            @content;
        }
    }

    @else if $size ==tablet-portrait-up {
        @media (min-width: 600px) {
            @content;
        }
    }

    @else if $size ==tablet-landscape-up {
        @media (min-width: 900px) {
            @content;
        }
    }

    @else if $size ==desktop-up {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else if $size ==big-desktop-up {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@mixin submit-button($color, $bgColor) {
    color: $color;
    background-color: $bgColor;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.15px;
    border-radius: 5px;
}

@mixin secondary-button($color, $bgColor, $borderColor) {
    color: $color;
    background-color: $bgColor;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid $borderColor;
    letter-spacing: -0.15px;
    border-radius: 5px;
}

@mixin link() {
    text-decoration-line: underline;
    color: var(--primary-blue-color);
    cursor: pointer;
}

@mixin error-font() {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@mixin splash-card() {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-white-color);
    border: 2px solid var(--primary-blue-color);
    border-radius: 7px;
}

@mixin bold-dark-heading($font-size:20px, $font-weight:600) {
    color: var(--font-black-2);
    text-align: center;
    font-family: Inter;
    font-size: $font-size;
    font-style: normal;
    font-weight: $font-weight;
    line-height: 150%;
    /* 30px */
    letter-spacing: -0.38px;
}