@import '../../../assets/styles/mixins.scss';


// .button {

// }
.submit-button {
    @include submit-button(var(--primary-white-color),var(--primary-blue-color));
    height: 48px;
    width: 100%;
    border: none
}
.secondary-button {
    @include secondary-button(var(--font-black),var(--primary-white-color),var(--primary-blue-color));
    height: 48px;
    width: 100%;
    
}
.secondary-button-dark {
    @include secondary-button(var(--primary-white-color),var(--background-grey),var(--primary-white-color));
    height: 48px;
    width: 100%;
    
}
.disabled-button {
    opacity: 0.7;
    pointer-events: none;
}