@import '../../../../../assets/styles/mixins.scss';

.task-details-wrapper {
    margin: 1.5rem;
    height: 90%;
    overflow-y: auto;

    header {
        background-color: white;
        padding: 1rem;
    }

    .assessment-details {
        display: flex;

        .product-image {
            height: 80px;
            width: 100px;
        }
    }

    .task-right-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            text-decoration: underline;
            color: var(--primary-blue-color);
            margin-right: 15px;
            cursor: pointer;
        }

        button {
            width: 150px;
        }

        .upload-media {
            width: 150px;
            position: relative;

            input {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: 0;
            }
        }
    }

    .note-row {
        position: relative;
        height: 48px;
        flex: 1;
        margin-left: 5px;

        label {
            position: absolute;
            top: -13px;
            left: 22px;
            background: linear-gradient(to top, #fff 50%, #efefef 50%);
            color: #0000008A;
        }

        input {
            width: 100%;
            height: inherit;
            border: 1px solid #0000003B;
        }

        .close-icon {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

.task-info-wrapper {

    .risk-assessment-multiselect {
        display: flex;

        >div:first-child {
            flex: 1;
        }

        .task-access {
            margin-bottom: 1.5rem;
            margin-left: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--font-gray3);
            padding: 7px;
            border-radius: 5px;
            text-transform: capitalize;
            cursor: pointer;

            img {
                scale: 0.7;
            }
        }
    }

    .label {
        color: #1A1D1E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex-grow: 1;
    }

    .disable-field {
        >div {
            pointer-events: none;
            background-color: #E9ECEF;
        }
    }

    .more-task-details {
        display: flex;
        justify-content: space-between;

        >div {
            flex: 1;
        }

        >div:not(:first-child) {
            margin-left: 10px;
        }
    }

    .action-row {
        display: flex;
        justify-content: space-between;

        .live-work {
            // flex: 1 1 0%;
            justify-content: start;
            display: flex;
            align-items: center;
        }

        .info-events {
            margin-left: 20px;
        }

        .action-buttons {
            display: flex;
            width: 15%;
            margin: auto;

            button {
                margin: 0 10px;
                border-radius: 5px;
            }
        }
    }
}