@import '../../../../../assets/styles/mixins.scss';

.product-model-card-wrapper {
    max-width: 250px;
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    background: #F8F8F8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 15px;
    max-height: 400px;
    .product-model-card-inner {
        display: flex;
        justify-content: space-between;
        height: 100%;

        .more-action-button {
            cursor: pointer;
            display: flex;
            justify-content: end;
            margin-bottom: 10px;

            .more-options {
                span {
                    margin: 0 5px;
                    font-family: Inter;
                }

                .edit {
                    img {
                        scale: 0.8;
                    }
                }
            }
        }

        div:nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            justify-content: center;
        }

        .pm-metrics {
            text-align: left;
            color: var(--font-black);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.304px;
        }

        .pm-model-edit {
            margin-top: 10px;

            button {
                width: 90%;
            }
        }
    }

    .pl-name {
        @include bold-dark-heading(20px, 600)
    }

    .pm-image {
        height: 172px;
        width: 200px;
        justify-content: center;
            align-items: center;
            display: flex;
        img {
            max-height: 172px;
            max-width: 200px;
        }
    }
}