@import '../../../assets/styles/mixins.scss';

.multi-select-chip {
    label {
        position: absolute;
        top: -8px;
        left: 10px;
        background-color: var(--primary-white-color);
        padding: 0 4px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--font-gray);
        z-index: 1;
    }
}