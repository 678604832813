@import '../../../assets/styles/mixins.scss';


// .button {

// }
.fieldset {
    div[role="radiogroup"] {
        display: flex;
        flex-direction: row;
    }
}