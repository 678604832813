@import '../../../../../assets/styles/mixins.scss';

.risk-assessment-card-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 8px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--primary-white-color);

    &:hover {
        background-color: #dbd7d7;
    }

    div {
        .ra-name {
            color: var(--font-black-2);
            font-family: Inter;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.456px;
        }

        .action-btns {
            text-align: right;

            img {
                margin: 0 4px;
            }

            .excel-icon {
                border-radius: 4px;
                border: 1px solid #1C2026;
                background: #D6EDDF;
                padding: 5px;
            }

            .more-options {
                span {
                    margin: 0 5px;
                    font-family: Inter;
                }

                .duplicate {
                    color: var(--primary-blue-color);
                }

                .access {
                    color: var(--primary-blue-color);
                    text-decoration: underline;
                }

                .edit {
                    img {
                        scale: 0.8;
                    }
                }
            }
        }

        .product-line,
        .product-model {
            color: var(--font-black-2);
            font-family: Roboto;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .product-line-name,
        .product-model-name {
            color: var(--font-black-2);
            font-family: Roboto;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .info-icon {
            margin-top: -4px;
            margin-left: 5px;
            scale: 0.8;
        }
    }

    .access-type {
        text-transform: capitalize;
        text-align: right;
        border-radius: 8px;
        padding: 4px 7px;
    }

    .restricted {
        background: var(--status-restricted);
        border: 1px solid #B91C1C;
    }

    .unrestricted {
        background: var(--status-unrestricted);
        border: 1px solid #0A832C;
    }

    .normal {
        background: var(--status-normal);
        border: 1px solid #009;
    }

    .task-status-wrapper {
        margin: 10px 0;

        .task-status {
            padding: 2px 7px;
        }

        .task-completed {
            border-radius: 4px;
            border: 1px solid #05E45E;
            background: rgba(5, 228, 94, 0.29);
        }

        .task-progress {
            border-radius: 4px;
            border: 1px solid #FFC700;
            background: rgba(255, 199, 0, 0.29);
        }
    }

    .restore-wrapper {
        .restore-text {
            text-decoration: underline;
            color: blue;
            cursor: pointer;
            margin-right: 5px;
        }

        .date-text {
            color: #1E1E1E;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
                   }
    }
}