.event-details-wrapper {
    margin: 1.5rem;
    height: 90%;
    overflow-y: auto;
    background-color: white;
    padding: 2rem;

    header {
        background-color: white;
        
    }

    .event-right-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
            text-decoration: underline;
            color: var(--primary-blue-color);
            margin-right: 15px;
            cursor: pointer;
        }

        button {
            width: 150px;
        }

        .upload-media {
            width: 150px;
            position: relative;

            input {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: 0;
            }
        }
    }


}

.event-info-wrapper {

    .heading {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-top: 16px;
    }

    .row1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .disable-field {
            >div {
                pointer-events: none;
                background-color: #E9ECEF;
            }
        }

        .single-selection-dropdown {
            margin-left: auto;
            width: 300px;
        }

    }

    .info-icon-right{
        margin-top: 5px;
        margin-right: 15px;
        margin-left: auto;
    }

    .info-icon-left{
        margin-top: 16px;
        margin-left: 15px;
    }

    .info-icon-center{
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 20px;
    }

    .event-status-wrapper{
        margin-left: auto;
    }

    .label {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 16px;
    }

    fieldset {
        div[role="radiogroup"] {
            display: flex;
            flex-direction: row;
        }
    }

    .action-check-box {
        // flex: 1 1 0%;
        justify-content: start;
        display: flex;
        align-items: center;
    }

    .action-buttons {
        display: flex;
        width: 30%;
        margin: auto;

        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }

    .final-recommendation {
        .final-note-row {
            display: flex;
            justify-content: flex-end;

            .separator {
                width: 20px;
            }

        }

        .button {
            width: 5%;
            height: 30px;
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }

   


}