.add-note-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .add-note-text {
        color: var(--primary-blue-color);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.15px;
        margin-left: 4px;
    }

    .note-row {
        position: relative;
        height: 48px;
        flex: 1;
        display: flex;

        label {
            position: absolute;
            top: -13px;
            left: 22px;
            background: linear-gradient(to top, #fff 50%, #efefef 50%);
            color: #0000008A;
        }

        input {
            width: 100%;
            height: inherit;
            border: 1px solid #0000003B;
        }

        .close-icon {
            position: absolute;
            right: 0;
            top: 0;
        }

        .save-icon {
            position: absolute;
            right: 20px;
            top: 2px;
            scale: 0.8;
        }
    }
}