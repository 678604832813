@import '../../../../../assets/styles/mixins.scss';

.risk-assessment-list-wrapper {
    margin: 20px;
    display: flex;
    flex-direction: column;
    height: 90%;
    .risk-assessment-header {
        background-color: var(--font-gray3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        padding: 8px 12px;

        .risk-assessment-info {
            display: flex;
            flex-direction: column;

            .heading {
                color: var(--font-black-2);
                // text-align: right;
                font-family: Roboto;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                padding-top: 10px;
                letter-spacing: -0.38px;
            }

            .model-list {
                margin: 20px 0 10px 0;
                .model-chip {
                    background-color: var(--surface-grey-color);
                    border-radius: 10px;
                    padding: 5px 10px;
                    margin: 4px;
                }
            }

        }

        .risk-assessment-actions {
            display: flex;
            align-self: baseline;

            button {
                margin-left: 10px;
            }
        }
    }

    .pl-search {
        margin-top: 10px;
        display: flex;
        img{
            margin-right: 1.5rem;
        }
    }

    .risk-assessment-list{
        flex: 1;
        overflow-y:auto
    }
}