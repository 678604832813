@import '../../../../../assets//styles/mixins.scss';

.product-line-page-wrapper {
    padding: 20px;

    .product-line-header {
        display: flex;
        justify-content: space-between;

        >div {
            color: var(--font-black);
            font-family: Roboto;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
        }

        .action-buttons {
            display: flex;

            div {
                margin: 0 10px;

                button {
                    border-radius: 7px;
                    padding: 0 10px;
                }

                .secondary-button {
                    border: 1px solid var(--primary-blue-color);
                }
            }
        }
    }

    .product-line-list {
        height: 75vh;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        width: 81vw;
        justify-content: flex-start;
    }

    .pl-search {
        display: flex;
        img{
            margin-right: 1.5rem;
        }
    }

}

.product-line-general-header{
    margin-bottom: 10px;
}