@import '../../assets/styles/mixins.scss';

.search-wrapper {
    position: relative;
    height: 3rem;
    input {
        height: inherit;
        width: inherit;
        padding-left: 3rem;
        border: 1px solid var(--font-gray1) !important;
        border-radius: 10px;
        font-size: 1.5rem;
    }
    .search-img {
        position: absolute;
        left: 0.5rem;
        top: 0.8rem;
    } 
}