@import '../../../../../assets/styles/mixins.scss';

.attendance-wrapper {
    padding: 20px;
    background-color: var(--primary-white-color);
    margin: 20px;

    .heading {
        color: var(--font-black);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        letter-spacing: -0.38px;
    }

    .add-manual {
        cursor: pointer;
        overflow: hidden;
        color: var(--primary-blue-color);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }

    .add-employee {
        display: flex;
        justify-content: space-between;

        >div {
            flex: 1;
        }

        >div:not(:first-child) {
            margin-left: 10px;
        }

        button {
            width: 50%;
            margin-left: 10px;
        }
    }

    .total-session-duration {
        display: flex;
        color: var(--Gray-87, rgba(0, 0, 0, 0.87));
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 20px 0px 30px 0px;

        >div:last-child {
            font-weight: 700;
            color: black;
            margin-left: 5px;
        }


    }

    .attendance-table {

        thead th {
            background-color: black;
            color: white;
        }

        // th:last-child {
        //     text-align: center;
        // }

        // tbody {
        //     td:last-child {
        //         height: 50px;
        //         text-align: center;
        //     }

        // }
    }
}

.add-manual-employee {

    .modal-content {
        padding: 16px !important;
    }

    .header {
        .label {
            flex: 1;
            text-align: center;
        }
    }

    .action-btns {
        display: flex;
        justify-content: center;

        button {
            width: 30%;
            margin: 0 10px;
        }
    }
}