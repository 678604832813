@import '../../../../../../assets/styles/mixins.scss';

.task-media-wrapper {
    .media-container {
        height: 45vh;
        width: 80%;
        align-items: center;
        justify-content: center;
        display: flex;

        .media {
            max-height: 45vh;
        }
    }

    .media-navigation-left {
        background: url("../../../../../../assets/images/svg/navigate-before.svg") #D6D6D6;
        background-repeat: no-repeat;
        background-position: center;
        width: 30px;
        height: 60px;
        border-radius: 4px;

    }

    .media-navigation-right {
        background: url("../../../../../../assets/images/svg/navigate-next.svg") #D6D6D6;
        background-repeat: no-repeat;
        background-position: center;
        width: 30px;
        height: 60px;
        border-radius: 4px;

    }
}