@import '../../assets/styles/mixins.scss';

.sidebar-wrapper {
    min-width: 200px;
    background-color: var(--primary-blue-color);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .sidebar-heading {
        text-align: left;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 125%;
        text-align: center;
        text-transform: uppercase;
        color: var(--font-gray1);
        padding-left: 35px;
        padding-top: 20px;
        text-align: left;
    }
}

.sidebar-nav {
    flex-grow: 3;
    align-self: center;
}

.sidebar-nav-items {
    list-style: none;
    padding: 10px 27px 10px 35px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-white-color);

    li {
        padding-top: 20px;
        padding-bottom: 20px;

        a {
            text-decoration: none;
            color: var(--primary-white-color);
            padding-left: 14px;
        }

        .name {
            margin-left: 20px;
        }

        .active {
            background-color: var(--primary-white-color);
            color: var(--font-black);
            width: 100%;
            display: flex;
            padding: 10px 10px 10px 14px;
            border-radius: 10px;

            img {
                filter: invert(18%) sepia(43%) saturate(5362%) hue-rotate(185deg) brightness(67%) contrast(138%);
            }
        }
    }
}

.logout-icon {
    align-self: center;
    flex-grow: 0.5;
    color: var(--primary-white-color);

    .logout-icon-image {
        margin-right: 15px;
    }
}

.logout-button {
    cursor: pointer;
}

.logout-button:hover {
    font-weight: 500;
}

.confirm-logout-modal {
    .header {
        display: flex;
        justify-content: center;
        p{
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
    .action-buttons {
        display: flex;
        width: 50%;
        margin: auto;

        button {
            margin: 0 10px;
            border-radius: 5px;
        }
    }
    .main-text {
        font-size: 1.3rem;
        margin: 0 0 1.5rem 0;
    }
}