@import "../../assets//styles/mixins.scss";

.signupForm {
    width: fit-content;
    min-width: 400px;
    text-align: center;
    padding: 0px 20px 20px 20px;

    @include for-size($global-phone-only) {
        min-width: unset;
    }

    .signup-heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.15px;
        color: #000000;
        margin-bottom: 15px;
    }

    .register-account {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
        color: var(--font-black);
        display: flex;
        justify-content: flex-start;
        margin-top: 12px;

        .second {
            @include link()
        }
    }

    .terms-conditions {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.15px;
        color: #000000;
        margin-top: -10px;
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .link {
            @include link()
        }

        .signup-checkbox {
            input[type="checkbox"] {
                margin-right: 8px;
                margin-top: 5px;
                transform: scale(1.2);
            }
            input[type="checkbox"]:checked {
                accent-color: var(--primary-blue-color)
            }
        }
        .error-message {
            @include error-font();
            color: red;
            font-size: 0.6rem;
        }
    }
}

.signup-wrapper {
    width: fit-content;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-white-color);
    border: 2px solid var(--primary-blue-color);
    border-radius: 7px;

    .logo {
        height: 125px;
        width: 244px;
    }
}