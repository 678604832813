@import '../../../assets/styles/mixins.scss';

.change-password-wrapper{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 20px;

    .header {
        display: flex;
        justify-content: space-between;

        .heading {
            color: var(--font-black);
            font-family: Roboto;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;

        }
    }

    .password-change-form{
        text-align: center;
        padding: 40px;
        background-color: var(--light-gray-background);
    }
}