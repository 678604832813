@import '../../../../../../assets/styles/mixins.scss';


.event-list-wrapper {}

.event-row-wrapper {

    margin-top: 10px;
    padding: 5px 10px 5px 0;
    box-shadow: -1px 2px 13px -15px rgba(0, 0, 0, 0.75);

    .row-1 {
        display: flex;
        justify-content: space-between;


        .heading {
            display: flex;
            padding: 15px 10px;
            align-items: center;

            .number {
                display: flex;
                width: 40px;
                height: 40px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 4px;
                background: #CCC;
            }

            .name-note-container {
                display: flex;
                flex-direction: column;

                .name {
                    margin-left: 1.25rem;
                    font-family: Roboto;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: -0.15px;
                }

                .note {
                    margin-left: 1.25rem;
                    font-family: Roboto;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: -0.15px;
                  max-width: 200px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }

        }

        .more-details {
            display: flex;
            align-items: center;

            .info-events {
                margin-right: 20px;
            }

            .copy-events {
                margin-right: 20px;
                cursor: pointer;
            }

            .unwanted-events {
                display: flex;
                flex-direction: column;
                align-items: start;

                .text {
                    font-family: Roboto;
                    font-size: 0.8rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 171.429% */
                    letter-spacing: -0.15px;
                }

                .action-btns {
                    display: flex;

                    button {
                        width: 60px !important;
                        height: 40px !important;
                        margin-right: 15px;
                    }
                }
            }

            .forward-arrow {
                margin-left: 10px;

                img {
                    scale: 0.8;
                }
            }
        }
    }

    .row-2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        .add-note-text,
        .event-sub-category-text {
            color: var(--primary-blue-color);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.15px;
            margin-left: 4px;
            cursor: pointer;
        }

        .event-sub-category-text {
            text-decoration: underline;
            margin-left: 10px;
        }
    }

    .note-row {
        position: relative;
        height: 48px;
        flex: 1;
        display: flex;

        label {
            position: absolute;
            top: -13px;
            left: 22px;
            background: linear-gradient(to top, #fff 50%, #efefef 50%);
            color: #0000008A;
        }

        input {
            width: 100%;
            height: inherit;
            border: 1px solid #0000003B;
        }

        .close-icon {
            position: absolute;
            right: 0;
            top: 0;
        }

        .save-icon {
            position: absolute;
            right: 20px;
            top: 2px;
            scale: 0.8;
        }
    }

    .sub-category-wrapper {
        display: flex;
        align-items: center;

        .MuiAutocomplete-root {
            flex: 1;
        }
    }

    .add-button {
        text-decoration: underline;
        color: var(--primary-blue-color);
        margin-left: 1rem;
        cursor: pointer;
    }

    .sub-category-list-wrapper {
        display: flex;
        width: 95%;
        margin-left: auto;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        flex-direction: column;

        .main-row {
            justify-content: space-between;
            display: flex;
            width: 100%;
        }

        .note-row {
            width: 98%;
            margin: 8px 0;
        }

        .more-details {
            display: flex;
            align-items: center;

            .copy-events {
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
}

.event-row-wrapper:nth-child(even) {
    background-color: white;

    .sub-category-list-wrapper {
        background-color: #EFEFEF;
    }

    .note-row label {
        background: linear-gradient(to top, #fff 50%, #fff 50%);
    }

}

.event-row-wrapper:nth-child(odd) {
    background-color: #EFEFEF;

    .sub-category-list-wrapper {
        background-color: white;
    }
}