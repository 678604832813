@import '../../../assets/styles/mixins.scss';

.deleted-risk-assessment-list-wrapper {
    padding: 20px;
    background-color: var(--light-gray-background);
    height: 100%;
   
    .header {
        display: flex;
        justify-content: space-between;

        >div {
            color: var(--font-black);
            font-family: Roboto;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5rem;
        }
       
    }
    .delete-risk-assessment-list{
        flex: 1;
        height: 73vh;
        overflow-y:auto
    }

    .ra-search {
        margin-top: 20px;
        display: flex;
        img{
            margin-right: 1.5rem;
        }
    }
   

}