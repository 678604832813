@import "../../assets//styles/mixins.scss";

.loginForm {
    width: fit-content;
    // min-width: 400px;
    text-align: center;
    padding: 0px 20px 20px 20px;

    .login-heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.15px;
        color: #000000;
    }

    .login-quote {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.15px;
        color: #000000;
        // width: 400px;
        height: 40px;
        margin-top: 15px;
        text-align: left;

        @include for-size($global-phone-only) {
            margin-bottom: 15px;
        }
    }

    .forgot-password {
        display: flex;
        justify-content: flex-end;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: -0.15px;
        text-decoration-line: underline;
        color: var(--primary-blue-color);
        margin-top: -4px; 
        margin-bottom: 10px;

        span {
            cursor: pointer;
        }
    }

    .register-account {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.15px;
        color: var(--font-black);
        display: flex;
        justify-content: flex-start;
        margin-top: 12px;

        .second {
            @include link()
        }
    }
}

.login-wrapper {
    width: fit-content;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--primary-white-color);
    border: 2px solid var(--primary-blue-color);
    border-radius: 7px;

    .logo {
        height: 125px;
        width: 244px;
    }
}