@import '../../../../../assets/styles/mixins.scss';

.task-card-wrapper {
    border-radius: 8px;
    background: #ededed59;
    width: 97%;
    margin: auto;
    padding: 15px;
    margin-top: 10px;
    box-shadow : -3px 2px 15px 0px #DBDBDB;
    cursor: pointer;

    .task-name {
        color: #1E1E1E;
        font-family: Inter;
        font-size: 1.45rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.38px;
    }
    .task-status-wrapper {
        margin: 10px 0;

        .task-status {
            padding: 2px 7px;
        }

        .task-completed {
            border-radius: 4px;
            border: 1px solid #05E45E;
            background: rgba(5, 228, 94, 0.29);
        }

        .task-progress {
            border-radius: 4px;
            border: 1px solid #FFC700;
            background: rgba(255, 199, 0, 0.29);
        }
}

    .task-details {
        color: #1E1E1E;
        font-family: Inter;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.38px;
        margin-top: 10px;
        word-break: break-word;

    }

    .sub-heading {
        margin-top: 10px;
        color: rgba(0, 0, 0, 0.54);
        font-family: Roboto;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
        /* 114.286% */
        letter-spacing: -0.15px;
    }
}

.task-card-wrapper:hover {
    background-color: var(--light-gray);
}